@import "./style-constants";

.rrmse-widget{

  display: flex;
  justify-content: center;
  align-items: center;

  &.on-widget{
    flex-direction: column-reverse;

    button.btn.svg-btn{
      position: absolute;
      right: 2rem;
      top: 2rem;
    }

    label{
      margin-top: 2rem;
      font-weight: bold;
      text-align: center;
      small{
        font-weight: lighter;
      }
    }
  }

  &.in-table{
    button.btn.svg-btn{
      height:1.2rem;
      width: 1.2rem;
      svg{
        height:1.2rem;
        width: 1.2rem;
      }
    }
  }

  span.rrmse{
    margin: 0 0.5rem;
    cursor: pointer;
    &.warning{
     color: $warning;
      svg{
        height:1.6rem;
        width:1.6rem;
        fill: $warning;
      }
    }

    //&:after{
    //  content: '/';
    //  margin-left: 1rem;
    //}

    &:not(:last-child){
      &:after{
        color: $default-text-color;
        content: '/';
        margin-left: 1rem;
      }
    }
  }
}