
@import 'style-constants';
#root {
  min-height: 100%;
  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
  /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $default-color;
  margin: 0;
}
.logo svg {
  fill: $default-color;
  width: 100px;
  margin-bottom: 20px;
}
.login-page {
  background-color: #ffffff;
  padding: 30px;
  min-width: 400px;
  max-width: 600px;
  border-radius: 15px;
  box-shadow: 0 0 80px $default-color-lighter;
  text-align: center;
  color: $default-color;
}

.registration-page {
  background-color: #ffffff;
  padding: 30px;
  min-width: 700px;
  max-width: 700px;
  border-radius: 15px;
  box-shadow: 0 0 80px $default-color-lighter;
  text-align: left;
  color: $default-color;
}

.mr1{
  margin-right:1rem!important;
}

/*.form-group{*/
  /*width:100%;*/
  /*position: relative;*/
  /*text-align: left;*/
/*}*/

/*.form-group.center{*/
  /*text-align: center;*/
/*}*/

/*.form-group.right{*/
  /*text-align: right;*/
/*}*/

/*.form-group.understroke {*/
  /*border-bottom: 1px solid #58595B;*/
/*}*/

/*.form-group > label {*/
  /*width:100%;*/
  /*font-size: 80%;*/
/*}*/

/*.form-group > .input-group > input[type="text"], .form-group > .input-group > input[type="password"] {*/
  /*width: 100%;*/
/*}*/

/*.input-group > .input-group-addon{*/
  /*float:right;*/
/*}*/

/*input[type="text"], input[type="password"] {*/
  /*border:none;*/
  /*background-color: inherit;*/
/*}*/
