.legend{
  display: flex;

  .legend-item{
    display: flex;
    align-items: center;
    margin-right: 1rem;

    span.marker{
      width: 1.2rem;
      height:1.2rem;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
    label{
      line-height: 1rem;
      opacity:0.8;
    }
  }
}

.capacity-table{
  color: #040b0f;
  width: 100%;
  margin: 0px;
  border: 1px solid #ccc;
  text-align: center;
  tr {
    height: 2rem;

    th {
      font-weight: bold;
      background-color: #f3f3f3;
      padding: 2px 8px;
    }

    td {
      line-height: 18px;
      padding: 0 8px;
      border: none;
      vertical-align: middle;
      position:relative;
    }
  }
  tr:nth-child(even) {
    background-color: #7a92b131;
  }

  button.show-on-hover {
    position: absolute;
    right: 1rem;
    top:0;
    display: none;
  }
  tr:hover {
    button.show-on-hover{
      display: block;
    }
  }
}

.table-lables{
    text-align: right;    
}
.dates-selection{
    width: 270px;
    border: '1px solid #ccc';
    display: 'inline-block';
    padding: '1rem';
    float: right;
}

.buttons-panel{
   width: 100%;
   margin-bottom: 10px;
}

.hours-td{
  border: '1px solid #ccc';
}
.network{
  margin: 5px;
  border: '1px solid #ccc';
  border-radius: 0.3rem;
  text-align: center;
  font-size: 16px;
  height: 30px;
  width: 20%;
  background-color: #ff7f50;
  color: black;
}
.electricity{
  margin: 5px;
  border: '1px solid #ccc';
  border-radius: 0.3rem;
  text-align: center;
  font-size: 16px;
  height: 30px;
  width: 20%;
  background-color: rgba(255, 217, 0, 0.507);
  color: black;
}
.total{
  color: black;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
.is-max{
  background-color: rgba(112, 155, 235, 0.74);
}
.peak-hour{
  background-color:rgba(255, 217, 0, 0.507);
}
.values{
  border-radius: 0.5rem;
  background-color: rgba(235, 141, 112, 0.315);
}

.params{
  background-color: #7a92b131;
}