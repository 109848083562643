$primary: #005ea3;
$success: #2ec572;
$secondary: #58595B;
$info: rgba(0, 94, 163, 0.7);
$warning: #ff2f00;
$danger: #9c1e00;
$light: rgba(88, 89, 91, 0.4);
$dark: #2a2b2d;
$--bs-primary: #005ea3;

$blue: rgba(0, 94, 163, 0.4);


$sidebar-width: 280px;
$sidebar-collapsed-width: 60px;
$header-height: 70px;
$load-height: 140px;

$primary: #005ea3;
$primary-50: rgba(0, 94, 163, 0.5);
$success: #2ec572;


$success-color: #2ec572;
$success-color-darker: #28964f;
$success-color-lighter: #2ff093;

$default-color: #005ea3;
$default-color-darker: #00457a;
$default-color-lighter: #006db6;
$default-color-10: rgba(0, 94, 163, 0.1);

$warning-color: #ff2f00;
$warning-color-lighter: rgba(255, 51, 0, 0.68);
$warning-color-darker: #9c1e00;

$default-text-color: #58595B;
$default-text-color-opacity-15: rgba(88, 89, 91, 0.15);

$blue-color: #7faed1;
$blue-bright-color: #2da8ff;

$chartColor0: #3498db;
$chartColor1: #2ecc71;
$chartColor2: #f1c40f;
$chartColor3: #e67e22;

$eco-green: #32a05f;
$eco-dark-green: #279152;
$eco-grey: #d2d4dc;