@import "style-constants";

div.dashboard-filter-panel{
  //height: 4rem;
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  padding: 1rem;
  box-sizing: border-box;
  justify-content: flex-end;
  margin: 0 12px;
  border: 1px solid lightgrey;
  align-items: center;
}

div.dashboard-filter-panel, div.time-series-table, div.widget-management-console {

  div.dropdown-container{
    width: auto;
    box-sizing: border-box;

    button.selected-item{
      font-size: 90%;
      letter-spacing: 0;
      padding: 0 2rem;
      align-items: center;
      display: flex;
      padding-right: 2rem!important;
    }

    button.selected-item:after {
      content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgNTMuNTQiPjx0aXRsZT5Bc3NldCAxMzY8L3RpdGxlPjxnIGlkPSJMYXllcl8yIiBkYXRhLW5hbWU9IkxheWVyIDIiPjxnIGlkPSJMYXllcl8xLTIiIGRhdGEtbmFtZT0iTGF5ZXIgMSI+PHBhdGggc3R5bGU9ImZpbGw6ICMwMDVlYTM7IiBkPSJNOTksMWEzLjUzLDMuNTMsMCwwLDAtNSwwTDUwLDQ1LjA5LDYsMUEzLjUzLDMuNTMsMCwwLDAsMSw2TDQ3LjQ2LDUyLjUxYTMuNDUsMy40NSwwLDAsMCwyLjUsMSwzLjU5LDMuNTksMCwwLDAsMi41LTFMOTguODksNkEzLjQ3LDMuNDcsMCwwLDAsOTksMVoiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg==");
      transform: rotate(0deg);
      position: absolute;
      right: 0.6rem;
      color: #005ea3;
      width: 1rem;
      height: 1rem;
      top: 0.2rem;
    }

    &.open button.selected-item:after {
      transform: rotate(180deg);
      top: 0.8rem;
    }

    div.dropdown{
      right:0;
      left: auto;
      width: max-content;
    }
  }

}

div.select-custom-month{
  color: $default-color;
  height: 2rem;
  border: 1px solid $default-color !important;
  border-radius: 1rem;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 0 1rem;
  margin: 0 1rem;

  b{
    align-self: center;
  }

  button {
    display: flex;
    justify-content: center;
    align-content: center;
    svg {
      width: 1rem;
      stroke-width: 0.2rem;
      stroke: $default-color;
    }
  }

}