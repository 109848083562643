@import 'style-constants';

.page-loader {
  max-height: 100px;
  min-height: 100px;
  width: 100%;
  text-align: center;
  display: inline-block;
  background-color: inherit;
  position: relative;

  .wait-text {
    position: absolute;
    top: 8px;
    left: 0;
    letter-spacing: 2px;
    width: 100%;
    color: $default-text-color;
  }

  img {
    max-height: inherit;
    width: auto !important;
  }

  .gooey {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 142px;
    height: 40px;
    margin: -20px 0 0 -71px;
    background: inherit;
    //filter: contrast(20);
  }

  .gooey .dot {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 12px;
    left: 15px;
    //filter: blur(4px);
    background: $default-color;
    border-radius: 50%;
    transform: translateX(0);
    animation: dot 2.8s infinite;
  }

  .dot {
    background: #2ec572 !important;
  }

  .gooey .dots {
    transform: translateX(0);
    margin-top: 12px;
    margin-left: 31px;
    animation: dots 2.8s infinite;
  }

  .gooey .dots span {
    display: block;
    float: left;
    width: 16px;
    height: 16px;
    margin-left: 16px;
    //filter: blur(4px);
    background: $default-color;
    border-radius: 50%;
  }

}

@-moz-keyframes dot {
  50% {
    transform: translateX(96px);
  }
}

@-webkit-keyframes dot {
  50% {
    transform: translateX(96px);
  }
}

@-o-keyframes dot {
  50% {
    transform: translateX(96px);
  }
}

@keyframes dot {
  50% {
    transform: translateX(96px);
  }
}

@-moz-keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}

@-webkit-keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}

@-o-keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}

@keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}

.widget-loader {
  position: absolute;
  top: -0.7em;
  right: -0.5rem;
  background-color: white;
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  justify-content: center;
  /* justify-items: center; */
  align-items: center;
  border-radius: 1rem;
  /* border: 1px solid grey; */
  opacity: 0.8;
  svg {
    width: 1.2rem;
    fill: $default-color;
    animation-name: widget-loader-rotation;
    animation-duration: 1.4s;
    animation-timing-function: cubic-bezier(.44,.74,.66,1.25);
    animation-iteration-count: infinite;
  }

  &.signal{
    background-color: grey;
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 0.2rem;
    opacity: 0.5;
    top: -0.5rem;
    animation: flashing 1s linear infinite;
    svg{
      display: none;
    }
  }
}

@keyframes widget-loader-rotation {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes flashing {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
  }
}

.dots-loader{
  //width:100%;
  //height: inherit;
  //display: flex;
  //justify-content: center;
  //align-items: center;

  width: 4rem;
  //height: 100%;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin-left: 0.4rem;

  .dots{
    border-radius: 50%;
    width: 0.6rem;
    height: 0.6rem;
    background-color: $default-color;
    margin-right: 0.4rem;
    animation: fadeIn 1s linear infinite;

    float: left;
  }

  .dot-1 {
    //animation: fadeIn 40ms;
  }
  .dot-2 {
    animation-delay: 0.2s;
  }
  .dot-3 {
    animation-delay: 0.4s;
  }

}

@keyframes fadeIn {
  //0% { opacity: 0; }
  //100% { opacity: 1; }
  0% { background-color: $default-color;}
  25% { background-color: $default-color;}
  50% { background-color: $success;}
  75% { background-color: $default-color;}
  100% {  background-color: $default-color }
}
