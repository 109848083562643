@import 'style-constants';

.navbar-header{
    position: absolute;
    top: 0;
    right:0;
    height: 4rem;
    background-color: white;
    left: 5rem;
    display: flex;
    align-items: center;
    box-shadow: 3px 0px 3px lightgrey;
    min-width: 950px;
}

ul.nav-left, ul.nav-right{
    list-style: none;
    display: flex;
    align-items: center;
}

ul.nav-left > li{
    margin-right: 1rem;
}

ul.nav-right{
    margin-right: 1rem;
    margin-left: auto;
}

.clock {
    font-family: "PT Sans", sans-serif;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 1rem;
    color: $default-color;
    display: flex;
    align-items: center;
    height: 1.5rem;
    margin-right: 1rem;
}

.clock svg{
    display: flex;
    justify-content: center;
    align-content: center;
}

.clock svg {
      fill: $default-color;
      stroke: $default-color;
      stroke-width: 2px;
      height: 1.3rem;
      width: 1.3rem;
      padding: 0px;
    margin: 0 0.3rem;
  }
