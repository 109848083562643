@font-face {
    font-family: AppFont;
    src: url('/fonts/PTSans.ttc') format('truetype');
    font-weight: normal;
    font-style: normal;
}
html{
    font-size: 14px;
}

body {
    font-family: AppFont, 'PT Sans', sans-serif;;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
    margin: 0;
}


/*@font-face {*/
    /*font-family: AppFont;*/
    /*src: url('/fonts/Exo2-Regular.ttf') format('truetype');*/
    /*font-weight: normal;*/
    /*font-style: normal;*/
/*}*/
/*html{*/
    /*font-size: 14px;*/
/*}*/

/*body {*/
    /*font-family: AppFont, 'Exo 2', sans-serif;*/
    /*font-size: 1rem;*/
    /*font-weight: 400;*/
    /*line-height: 1.5;*/
    /*color: #212529;*/
    /*text-align: left;*/
    /*background-color: #fff;*/
    /*margin: 0;*/
/*}*/
