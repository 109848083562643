
@import "style-constants";
div.sidebar{
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: 5rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    //margin-top:2rem;
    background-color: $default-color;
}

a.sidebar-btn{
    text-decoration: none;
}

button.sidebar-btn{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    border: none;
    background: none;
    padding: 0;
    flex-direction: column;
    height: auto;
    align-items: center;
    margin: 1rem 0;
    cursor: pointer;
    min-height: 2rem;
}

.sidebar-btn > label {
    color:white;
    opacity: 0;
    margin-top: -50%;
    transition: margin-top 0.3s ease-in-out, opacity 0.2s ease-in-out;
    font-size: 0.6rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.1rem;
    cursor: pointer;
    white-space: normal;
}
.sidebar-btn:hover > label, .sidebar-btn.active > label {
    margin-top:0%;
    opacity:1;
}

.sidebar-btn svg {
    fill: white;
    /*stroke: white;*/
    /*stroke-width: 0.2rem;*/
    /*padding: 0.3rem;*/
    height: 2.5rem;
    width: 100%;
    background-color: $default-color;
}

.sidebar-btn.blue-hover:hover svg, .sidebar-btn.blue-hover.active svg {
    fill: #4adeff;
}
.sidebar-btn.blue-hover > label{
    color: #4adeff;
}

.sidebar-btn.green-hover:hover svg, .sidebar-btn.green-hover.active svg {
    fill: #6aff8f;
}
.sidebar-btn.green-hover > label{
    color: #6aff8f;
}

.sidebar-btn.yellow-hover:hover svg, .sidebar-btn.yellow-hover.active svg {
    fill: #F7FF63;
}
.sidebar-btn.yellow-hover > label{
    color: #F7FF63;
}


div.dashboard-list{
    position: absolute;
    left: 5rem;
    top: 0;
    bottom: 0;
    background-color: $default-color-lighter;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    width:0;
    transition: width 0.3s ease-in-out;
    color: white;
}

div.dashboard-list.open{
    width: 25rem;
}

div.dashboard-list > h5{
    color: white;
    letter-spacing: 0.3rem;
    /*text-transform: uppercase;*/
    font-size: 1rem;
    /*text-shadow: -1px -1px 1px;*/
    text-align: end;
    padding: 1rem;
    position: relative;
    height: 2rem;
    margin: 0;
}

//div.dashboard-list > h5:after {
//    width: 0%;
//    height: 0;
//    content: " ";
//    border-bottom: 1px solid white;
//    position: absolute;
//    transition: width 1s ease-in-out;
//    top: calc(100% + 0.1rem);
//    right: 0;
//}
//div.dashboard-list.open > h5:after {
//    width: 100%;
//}



ul.dashboard-tree{
    list-style: none;
    padding-left: 0;
    margin:0;
}

ul.dashboard-tree > li.tree-item {
    cursor:pointer;
    margin: 0.4rem 0;
    padding-left: 1rem;
}

ul.dashboard-tree > li.tree-item:hover {
    background-color: $default-color;
    border-radius: 1rem;
    margin-left: 0.5rem;
}


li.tree-item > a {
    color: white;
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
    text-decoration: none;
    position:relative;
    width: 100%;
    display: block;
    padding: 0.4rem;

    &:before {
        /*width: 0%;*/
        height: 0;
        content: " ";
        border-bottom: 1px solid white;
        position: absolute;
        /*transition: width 0.2s ease-in-out;*/
        top: calc(100% + 0.1rem);
    }

    &:hover > a {
        color: white;
    }

}

ul.dashboard-tree > li.tree-item {
    margin-bottom: 0.6rem;
}

li.tree-item.db-group{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0.5rem 1rem!important;
    margin: 0!important;

    &:hover{
        background-color: inherit!important;
    }

    div.db-group-title{
        display: flex;
        span.db-group-collapsed {
            width:1.2rem;
            height:1.2rem;
            position: relative;
            margin-right: 1rem;

            &:before {
                content: url('../sources/images/interface/arrow-white.svg');
                width: 1rem;
                height: 1.2rem;
                color: white;
                margin: 0;
                position: absolute;
                transform: rotate(-90deg);
            }
            &.open:before {
                transform: rotate(0deg);
            }
        }

    }
}

/***
SCROLLBAR
***/
div.dashboard-list::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem;
}
div.dashboard-list::-webkit-scrollbar-track-piece { background-color: #3498db;}
div.dashboard-list::-webkit-scrollbar-thumb { height: 3rem; background-color: #4adeff;}
div.dashboard-list::-webkit-resizer { background-color: #666;}