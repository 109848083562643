@import 'style-constants';
.form-group {
    text-align: left;
    margin-bottom: 1rem;
}

.form-group.understroke{
    border-bottom: 1px solid #d7d7d7;
}

.form-group.center {
    text-align: center;
}

.form-group.right {
    text-align: right;
}

.input-group{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width:100%;
    //height:1.8rem;
    height: 2rem;
}
.input-group.not-inline > .input-group-prepend{
    width:100%;
}
.input-group.not-inline{
    height: 4rem;
}

.input-group > input[type="text"], .input-group > input[type="password"] {
    /*flex-grow: 1;*/
    position: relative;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    min-width: 0;
    color: $default-text-color;
    height: 1.8rem;
    margin: 0;
    padding: 0 1rem;
    text-overflow: ellipsis;
}

.input-group > input[type="text"]:after{
    content: url('../sources/images/interface/edit.svg');
    width:1rem;
    height:1rem;
    position:absolute;
    top:0;
    right:0;
}

.input-group-addon {
    height: inherit;
}
.input-group-addon > button {
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

input:-webkit-autofill{
    background-color: #ffffff!important;
    color: #3498db !important;
    border-radius: 1rem;
    height: 0.6rem;
    margin-top: 0.2rem;
}

label {
    display: inline-block;
    margin-bottom: .2rem;
    color: #737373;
    letter-spacing: 0.1rem;
}

.form-control{
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    margin: 0!important;
}

.input-group.file-uploader{
    height:5rem;
    display: flex;
}

.file-uploader > span.load-icon-preview{
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-content: center;
    background-color: #ededed;
    border-radius: 0.3rem;
    box-shadow: 0 0 2px #ededed;
}

span.load-icon-preview img{
    width: 100%!important;
    height: 100%!important;
}

input[type=time]{
    border: 0;
    color: $default-color;
    position: relative;
    flex: 1 1;
}

.head-input{
    width: 70% !important;
    font-weight: Bold;
    font-size: 16px;
    margin: 0 auto;
}

.mixed-input{
    display: flex;
    margin-bottom: 1rem;
}

