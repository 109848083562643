@import "style-constants";

.pricing-header-box{
    width: 800px;
}
.pricing-header{
    margin-right: 20px !important;
    display: inline-block;
}
.pricing-balance{
    display: inline-block;
}
.pricing-square{
    width: 250px;
    height: 460px;
    text-align: center;
    border: 1px solid #ededed;
    padding: 20px 20px 0 20px;
    border-radius: 15px;
    margin: 2rem;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.pricing-square .pricing-square-description {
    width: 208px;
    height: 72px;
    font-size: 16px;
    line-height: 1.5;
    margin-top: 10px;
}
.pricing-square .pricing-square-price{
    font-weight: 700;
    font-size: 35px;
    text-transform: uppercase;
    letter-spacing: -.01em;
    margin: 30px 0;
}
.pricing-square .pricing-square-price span {
    font-size: 14px;
}
.pricing-square .pricing-square-item {
    font-size: 14px;
    line-height: 1.5;
}
.pricing-square .pricing-square-description-bottom{
    position: absolute;
    margin-top: 20px;
}
.tariff-title{
    position: absolute;
    top: -12px;
    background-color: white;
    padding: 0 8px;
    color: $default-color;
    letter-spacing: 4px;
    font-weight: bold;
    left: 13px;
}
.tariff-btn{
    margin-top: 30px;
    height: 3rem !important;
    font-size: 20px !important;
}
.hide{
    display: none;
}

.pricing-square-active{
    border: 3px solid #2ec572;
}
.tariff-title-active{
    color: #2ec572;
}
.pricing-square-price-active{
    color: #2ec572;
}
.tariff-btn-active{
    background-color: #2ec572 !important;
    color: white;
    border-radius: 1rem;
    border: 1px solid #2ec572;
}
.tariff-btn-active:hover{
    cursor: auto;
}



.add-balance-btn{
    position: relative;
    display: inline-block;
    cursor: pointer;
    border: 0;
    text-decoration: none;
    background: transparent;
    margin: 2px 10px;
    width: 200px;
    height: auto;
    vertical-align: bottom;
}
.add-balance-btn-circle{
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    margin: 0;
    width: 26px;
    height: 26px;
    background: #fff;
    border: 1px solid $default-color;
    border-radius: 13px;
}
.add-balance-btn-plus{
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 2px;
    left: 7px;
    color: $default-color;
    font-size: 20px;
    margin: auto;
    background: transparent;
}
.add-balance-btn-text{
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: -6px;
    right: 0;
    left: 0;
    color: transparent;
    font-weight: 700;
    text-align: center;
}
.add-balance-btn:hover .add-balance-btn-circle{
    width: 100%;
    background-color: $default-color;
}
.add-balance-btn:hover .add-balance-btn-plus{
    color: #fff;
    transform: rotate(180deg);
    left: 10px;
    top: 2px;
}
.add-balance-btn:hover .add-balance-btn-text{
    color: #fff;
}