button.dropdown-btn {
  border: none;
  position: relative;
  padding-right: 2rem;
  font-weight: bold;
  color: #005ea3;
  height: 1.6rem;

  &::after {
    content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgNTMuNTQiPjx0aXRsZT5Bc3NldCAxMzY8L3RpdGxlPjxnIGlkPSJMYXllcl8yIiBkYXRhLW5hbWU9IkxheWVyIDIiPjxnIGlkPSJMYXllcl8xLTIiIGRhdGEtbmFtZT0iTGF5ZXIgMSI+PHBhdGggc3R5bGU9ImZpbGw6ICMwMDVlYTM7IiBkPSJNOTksMWEzLjUzLDMuNTMsMCwwLDAtNSwwTDUwLDQ1LjA5LDYsMUEzLjUzLDMuNTMsMCwwLDAsMSw2TDQ3LjQ2LDUyLjUxYTMuNDUsMy40NSwwLDAsMCwyLjUsMSwzLjU5LDMuNTksMCwwLDAsMi41LTFMOTguODksNkEzLjQ3LDMuNDcsMCwwLDAsOTksMVoiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg==");
    position: absolute;
    right: 0.6rem;
    color: #005ea3;
    width: 1rem;
    height: 1.6rem;
  }

  &.opened {
    &::after {
      transform: rotate(180deg);
    }
  }

  &.closed {
    &::after {
      transform: rotate(0deg);
    }
  }
}

table.group-planning {

  tr{
    vertical-align: middle;
  }

  tr.collapsed-row {
    height: auto !important;
    td {
      transition: line-height ease-in-out 0.2s;
      height: auto !important;
      overflow: hidden;
    }

    &.opened {
      td {
        line-height: 1.4rem;
      }
    }
    &.closed {
      td {
        line-height: 0rem;
      }
    }
  }

  tr.group-row{
    th{
      height: 2.6rem;
      background-color: #ffffff!important;
      vertical-align: middle;
    }
  }
  tr.collapsed-row {
    td {
      line-height: 1.6rem;
      background-color: #f3faff !important;
      border-top: 1px solid #ededed;
    }
  }

  tr.total-row{
    th{
      height: 2.6rem;
      background-color: #ececec !important;
      vertical-align: middle;
    }
  }

  th.hour-window{
    background-color: white;
  }

  th.hour-window + th.non-hour-window{
    border-left: 1px solid red;
  }

  th.non-hour-window + th.hour-window{
    border-left: 1px solid red;
  }

}