@import "style-constants";

.capacity-header {
  display: flex;
  flex-direction: row;
}

.capacity-name {
  margin-top: 5px !important;
}

.capacity-grid {
  padding: 2rem 1rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-y: scroll;
  //height: 1000px;

  flex-wrap: wrap;
  align-content: flex-start;

  &::-webkit-scrollbar {
    visibility: hidden;
    width: 0;
  }

}

.capacity-view {
  flex-direction: row;
  width: 100%;
  overflow: hidden;

  .detail-view {
    width: 70%;
  }

  .full-view {
    width: 100%;

  }

}

.capacity-spacer {
  display: flex;
  position: absolute;
  left: 49%;
  top: 50%;
}

.capacity-item {
  height: 240px;
  width: 180px;
  margin: 0;
  padding: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  border-radius: 1rem;

  .image {
    display: flex;
    width: 120px;
    height: 120px;
    padding: 20px;
    justify-content: center;
    align-items: center;

    img {
      max-height: 100px !important;
      max-width: 100px !important;
    }

    .add-item {
      fill: $default-color;
      margin-left: auto;
      margin-right: auto;
      height: 64%;
      width: 64%;
    }
  }

  label {
    margin-top:1rem;
    letter-spacing: unset;
  }

  &.capacity-folder{
    .image {
      background: url('../sources/images/interface/folder.svg') no-repeat;
      background-size: contain;
      padding: 40px;
      padding-top: 50px;
      padding-bottom: 30px;
      img{
        max-height: 60px !important;
        max-width: 60px !important;
      }

    }
  }

  &:hover{
    box-shadow: 2px 2px 8px rgba(0,0,0,0.5);
    label{
      font-weight: bold;
    }
  }

  .status-view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 16px;
    margin-bottom: 10px;

    img {
      height: 12px;
      width: 12px;
    }

    .status {
      width: 14px;
      height: 14px;
      border-radius: 50%;

      border: 1px solid #FFFBFB;
      box-sizing: border-box;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

      &-active {
        background: green;
      }

      &-disactive {
        background: lightgray;
      }

      &-warn {
        background: yellow;
      }

      &-error {
        background: red;
      }

      &__image {
        width: 16px;
        fill: #ec2929ad;
      }

    }

  }
}

/*
.capacity-item:hover {
  border: 1px solid $default-color;
  //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  transition: box-shadow 0.6s linear;
}
*/
.capacity-item-selected {
  border: 2px solid $primary-50 !important;
  //border-radius: 15px;
  //background-color: $primary;
  box-shadow: 2px 2px 8px rgba(0,0,0,0.5);
  label{
    font-weight: bold;
  }
}
/*
.capacity-folder {
  box-shadow: 2px 2px 4px #c8c8c8;
  border-radius: 0.5rem;
  border-bottom: 3px #eaeaea double;
  border-right: 3px #eaeaea double;
  border-left: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
}
*/

.capacity-layout-grid {
  width: 100%;
}

.capacity-resize {

  width: 40px;
  height: 40px;
  display: flex;
  padding: 5px;
  align-items: center;
  transform: rotate(180deg);

  &-deactivated {
    width: 40px;
    height: 40px;
    display: flex;
    padding: 5px;
    align-items: center;
  }
}

.capacity-detail {
  padding-top: 10px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  width: 70%;

  &__header {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;

    &__text {
      color: $default-color;
      font-size: 1.5em;
      font-weight: bold;
      line-height: 1;
    }

    &__selector {
      color: #58595B;
      font-size: 1.2em;
      padding-right: 15px;
    }

    &__actions {

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 20px;
      width: 70px;
      padding-right: 20px;

    }
  }

  &__content {
    margin-top: 2rem;
  }

}