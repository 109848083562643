@import "style-constants";
/*
div.modal-portal{
    display: block;
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

    div.modal-layout {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #0000009e;
        -webkit-transition: opacity 200ms ease-in;
        -moz-transition: opacity 200ms ease-in;
        transition: opacity 200ms ease-in;
        pointer-events: none;
        margin: 0;
        padding: 0;
        animation: opacity-up 0.4s ease;
        overflow-y: auto;
    }


div.modal-layout::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem;
}
div.modal-layout::-webkit-scrollbar-track-piece { background-color: #d0d0d0;}
div.modal-layout::-webkit-scrollbar-thumb { height: 3rem; background-color: #005ea3;}
div.modal-layout::-webkit-resizer { background-color: #d0d0d0;}


.modal-container {
    margin: 3rem auto;
    pointer-events: auto;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 1rem;
    outline: 0;

    animation: slide-up 0.4s ease;
}

button.close-btn {
    border:none;
    background: none;
    border-radius: 1rem;
    height: 2rem;
    width: 2rem;
    line-height: 1.9rem;
    font-size: 2rem;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: #bfbfbf;
}

button.close-btn:hover {
    background-color: #f8f8f8;
}


.modal-title {
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    color: $default-color;
}
.modal-header{
    border-bottom: 1px solid #f7f7f7 ;
}

.modal-footer{
    text-align: center;
    border-top: 1px solid #f7f7f7 ;
}

.modal-header, .modal-body, .modal-footer {
    margin:0;
    padding: 2rem;
}

@media (min-width: 576px) {
    .modal-container {
        max-width: 500px;
    }
}
@media (min-width: 768px) {
    .modal-container {
        max-width: 700px;
    }
}
@media (min-width: 960px) {
    .modal-container {
        max-width: 900px;
    }
}
@media (min-width: 1200px) {
    .modal-container {
        max-width: 1200px;
    }
}


$keyframes opacity-up {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

$keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
*/

div.modal-body > div.modal-footer{
    margin: 1rem -1rem -1rem -1rem!important;
    button{
        margin-left: 1rem;
    }

}