@import 'style-constants.scss';

$scenario-back-color: #ececec;

.box-shadow-style {
  box-shadow   : 2px 2px 4px #c8c8c8;
  border-bottom: 3px #eaeaea double;
  border-right : 3px #eaeaea double;
  border-left  : 1px solid #eaeaea;
  border-top   : 1px solid #eaeaea;
}


.flex-container {
  display       : flex;
  flex-direction: row;

}

.scenario-editor {
  display: flex;

  flex-direction: column;

  &.__header {
    display: flex;

    flex-direction: column;
  }

  &.__statement {
    display       : flex;
    flex-direction: column;
    overflow-y    : scroll;
    height        : 750px;
  }


}

.accordion {
  display       : flex;
  flex-direction: column;
  margin        : 10px;
  width         : 100%;

  &__name {
    font-weight: bold;
    padding-top: 15px;
  }

  &__disable__span {
    font-style: italic;

  }

  &__button {
    margin-right: 5px;
  }

  &__header {
    display         : flex;
    flex-direction  : row;
    justify-content : space-between;
    background-color: $scenario-back-color;
    border-radius   : 5px;

    &__left {
      justify-self: flex-start;
    }

    &__right {

      justify-self: flex-end;
    }
  }

  &__action-list {
    display       : flex;
    flex-direction: row;
    list-style    : none;
    padding-left: 10px;
    margin: 0.6rem;
    &>li {
      margin-right: 10px;

    }

    &__item {
      margin-top: 5px;
    }
  }

  &__button {
    margin-top: 15px;
  }

  &__content {

    margin: 20px;

    &--disabled {
      opacity       : 0.3;
      pointer-events: none;
      user-select   : none;
    }

  }


}

.capacity-list {
  list-style: none;
  display   : inline-flex;
  flex-wrap: wrap;

  &__item {
    background-color: $scenario-back-color;
    border-radius   : 5px;
    padding         : 10px;
    margin          : 5px;
    display         : inline-flex;

    &__text {
      padding: 5px;
    }


  }
}
