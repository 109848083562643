div.alert {
    /*margin: 0.5rem auto;*/
    /*border-radius: 0.25rem;*/
    /*padding: 1rem;*/
    /*display: inline-block;*/
    /*width: 100%;*/
    /*box-sizing: border-box;*/
    margin: 0.5rem auto;
    border-radius: 0.25rem;
    padding: 1rem;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

div.alert.alert-danger {
    color: #9c1e00;
    border:1px solid #9c1e00;
}