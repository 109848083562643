@import "style-constants";

div.dict-of-time{

  height: auto;

    div.hours-line{
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
      min-width: 760px;

      button.hour{
        cursor: pointer;
        border: 1px solid $default-color;
        position: relative;
        margin:0 3px 0 0;
        height: 2rem;
        background-color: white;
        width: 24px;
        min-width: 1.6rem;
        border-radius: 4px!important;

        span.label{
          position: absolute;
          left: 18px;
          bottom: -1.8rem;
          font-size: 0.8rem;
          line-height: 1.6rem;

          &:after{
            content: "";
            height: 2.4rem;
            width: 1px;
            background-color: grey;
            position: absolute;
            bottom: 1.4rem;
            left: 6px;
          }

        }


        &:hover{
          background-color: $blue-bright-color;
        }


        &.active{
          background-color: $default-color-lighter;
        }

        &:first-child{
          &:before{
            content: '00';
            position: absolute;
            right: 1.2rem;
            bottom: -1.8rem;
            font-size: 0.8rem;
            line-height: 1.6rem;
          }
          &:after{
            content: "";
            height: 2.4rem;
            width: 1px;
            background-color: grey;
            position: absolute;
            bottom: -0.4rem;
            left: -3px;
          }
        }
      }

    }

}