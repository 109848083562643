//@import './custom-bootstrap';
@import './style-constants';

button {
  font-size: 1rem !important;
  line-height: 1.4rem !important;
  letter-spacing: .1rem !important;
  border-radius: 2rem !important;
  white-space: nowrap!important;
  flex-wrap: nowrap!important;
  display: flex!important;
  align-items: center!important;

  &.btn-squared{
    border-radius: 2px !important;
  }

  > *{
    margin-left: 0.5rem;

    &:first-child{
      margin-left: 0;
    }
  }

  &.btn {
    padding: 0.4rem 1rem !important;
  }

  svg {
    max-height: 2rem;
    max-width: 2rem;
    height: 1.4rem;
    width: 1.4rem;
    fill: inherit;
  }

  &.svg-btn {
    border: none;
    padding: 0!important;
    background-color: transparent;

    &.success {
      fill: $success;
    }

    &.default, .primary {
      fill: $primary;
    }

    &.danger{
      fill: $danger;
    }

    svg{
      height: 1.8rem;
      width: 1.8rem;
    }

    &.sm{
      height: 1.4rem!important;
      svg{
        height: 1.4rem;
        width: 1.4rem;
      }
    }

  }

  &.btn-outline-primary {
    svg {
      fill: $primary!important;
      color: $primary!important;
    }
    &.active {
      color: #fff !important;
      svg {
        fill: #fff!important;
      }
    }
    &:hover {
      color: #fff !important;
      svg {
        fill: #fff !important;
      }
    }
  }

  &.btn-outline-success {
    svg {
      fill: $success;
    }
    &.active {
      color: #fff !important;
      svg {
        fill: #fff;
      }
    }
    &:hover {
      color: #fff !important;
      svg {
        fill: #fff !important;
      }
    }
  }

  &.btn-outline-danger {
    svg {
      fill: $danger;
    }
    &.active {
      color: #fff !important;
      svg {
        fill: #fff;
      }
    }
    &:hover {
      color: #fff !important;
      svg {
        fill: #fff !important;
      }
    }
  }

  &.small{
    width: 1rem!important;
    height: 1rem!important;
    border-radius: 0.5rem;

    svg {
      width: 1rem!important;
      height: 1rem!important;
    }

  }

  &.big{
    width: 3rem!important;
    height: 3rem!important;
    border-radius: 0.5rem;

    svg {
      width: 3rem!important;
      height: 3rem!important;
    }

  }

  &:focus{
    box-shadow: none!important;
  }
}

/**
 PREVIOUS-BTN
  */

.svg-icon svg {
  stroke: $primary;
  fill: $primary;
  height: 1rem;
}

.svg-icon {
  display: inline-block;
  margin: 0 0.7rem;
}

.svg-icon.previous-btn svg {
  pointer-events: none;
  transform: rotate(180deg);
}

/**
BURGER-BTN
*/
button.burger-btn {
  position: relative;
  height: 2rem!important;
  width: 1.8rem!important;
  min-width: 1.6rem !important;
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  background-color: transparent;
  border: none;

  .menu-bar {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0.5rem;
    //width: 45px;
    //height: 40px;
    //margin: 30px 0 20px 20px;
    //cursor: pointer;
  }

  .bar {
    height: 2px;
    width: 100%;
    background-color: $primary;
    display: block;
    border-radius: 5px;
    transition: 0.3s ease;
  }

  .bar1 {
    transform: translateY(-4px);
  }

  .bar3 {
    transform: translateY(4px);
  }

  &.opened {
    .bar1 {
      //transform: translateY(1px) rotateZ(-45deg);
      width:80%;
      transform: translateY(-3px) rotateZ(30deg);
    }

    .bar2 {
      opacity: 0;
    }

    .bar3 {
      width:80%;
      //transform: translateY(-3px) rotateZ(45deg);
      transform: translateY(3px) rotateZ(-30deg);
    }
  }

  .hamburger-lines {
    //display: block;
    height: 1.6rem;
    width: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .hamburger-lines .line {
    display: block;
    height: 2px;
    width: 100%;
    border-radius: 10px;
    background: #0e2431;
  }

  .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }

  .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }

  &.opened {
    .hamburger-lines .line1 {
      transform: rotate(45deg);
    }

    .hamburger-lines .line2 {
      transform: scaleY(0);
    }

    .hamburger-lines .line3 {
      transform: rotate(-45deg);
    }
  }
}

/**
COLOR-PICKER
*/
button.color-picker-btn{
  border: none!important;
  color:white!important;
  padding: 0.5rem!important;

  div{
    display: flex;
  }

  svg{
    fill: white!important;
  }
}


div.checkbox-item{

  &:hover{
    label{
      text-decoration: underline!important;
    }
  }
}

button.big {
  width: 3rem!important;
  height: 3rem!important;
  border-radius: 0.5rem;
}
button.big svg {
  min-width: 3rem!important;
  min-height: 3rem!important;
}


label.as-link{
  color: $default-color;
  text-decoration: underline;
  cursor: pointer;
}

