@import "style-constants";

.empty-data{
  width: 100%;
  height: calc(100% - 3rem);
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  flex-flow: column;

  font-size: 90%;
  letter-spacing: 0.15rem;
  color: grey;

  svg {
    fill: $default-color;
    width: 3rem;
    height: 3rem;
  }
}

/**
SCROLL-BAR for widget grid-item
*/
div.react-grid-item{
  overflow: auto;
}
div.react-grid-item::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}

div.react-grid-item::-webkit-scrollbar-track-piece {
  background-color: #d0d0d0;
  border-radius: 2px;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

div.react-grid-item::-webkit-scrollbar-thumb {
  height: 3rem;
  background-color: $default-color;
  border-radius: 2px;
}

div.react-grid-item::-webkit-resizer {
  background-color: #d0d0d0;
}


.widget {
  position: relative;
  width:100%;
  height:100%;
  /* min-width: calc(100%); */
  /* min-height: calc(100% + 2rem); */
  /* margin-top: -1rem; */
  background-color: white;
  /* margin-left: -1rem; */
  border-radius: 0.5rem;
  /* padding: 1rem; */
  box-sizing: border-box;

  .recharts-wrapper{
    user-select: none;
  }


  div.widget-header {
    width: 100%;
    height: 30px;

    label {
      font-size: 14px;
      font-weight: bold;
      color: $default-color;
      //line-height: 14px;
      margin: 2px 0;
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
    }

  }

  .widget-management-console{
    display: flex;
    position:absolute;
    top: -30px;
    right:0;
    button{
      height: 1.8rem!important;
      margin: -0.2rem 0.2rem 1rem 0.2 rem!important;
      //margin-left: 1rem!important;
    }
  }

  //.widget-management-console > button, .widget-management-console > a > button, .widget-management-console > div.dropdown-container, .widget-management-console > div.dropdown-container  > div.dropdown-container, , .widget-management-console > div.dropdown-container  > div.dropdown-container > button{
  //  height: 1.8rem!important;
  //  margin: 0.2rem!important;
  //  margin-left: 1rem!important;
  //  button{
  //    height: 1.8rem!important;
  //    margin: 0.2rem!important;
  //    margin-left: 1rem!important;
  //  }
  //}

  .collapsed-widget-console{
    position: absolute;
    top: -30px;
    right:0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    .collapsed-menu{
      display:flex;
      z-index: 5;
      background-color: white;

      > * {
        margin-right: 0.8rem;
      }

      button{
        height: 1.8rem!important;
        margin: 0.2rem!important;
      }
    }
  }

}

.widget:hover {
  .widget-props-panel {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
    z-index: 3;
  }
}

.widget-props-panel {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0s linear 0.3s;
  display: flex;
  justify-content: center;

  background: white;
  box-shadow: rgba(88, 89, 91, 0.35) 1px 1px 20px;
  border-radius: 1.5rem;
  width: fit-content;
  height: 3rem;
  align-items: center;

  button {
    margin: 0 0.5rem;
  }

}


/**
CHART WIDGET STYLE
*/
ul.legend{
  display: flex;
  font-size: 85%;
  list-style: none;
  padding: 0;

  li{
    margin-left: 1rem;
    border-radius: 0.5rem;
    padding: 0 0.25rem;
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.recharts-surface{
  font-size: 70%;
}


div.function-value{
  position: absolute;
  bottom: 40%;
  font-size: 120%;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: bold;
}


/**
PIVOT-TABLE
*/
.pivot-table{
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: -30px;

  .summary-table {
    height: 100%;
    float: left;
    overflow: auto;
    border-top-left-radius: 15px;

    table {
      border-spacing: 1px;
      border-collapse: separate;

      tr:nth-child(even){
        background-color: inherit!important;
      }

      tr:first-child {
        td {
          &.selected {
            border-top-color: $default-color!important;
            border-top-width: 1px!important;
            background-color: #CCDBF0;
          }

          &.weekend {
            //border-top-color: #F099A6!important;
            //border-top-width: 1px!important;
          }
        }
      }

      tr:last-child {
        td {
          &.selected {
            border-bottom-color: $default-color!important;
            border-bottom-width: 1px!important;
          }

          &.weekend {
            //border-bottom-color: #F099A6!important;
            //border-bottom-width: 1px!important;
          }
        }
      }

      td {
        //border-right: 1px solid #efefef!important;
        //border-bottom: 1px solid #efefef!important;
        border: 1px solid #efefef!important;
        color: $default-text-color;
        min-width: 36px;
        text-align: center;
        font-size: 14px;
        white-space: nowrap;

        &.ready {
          background-color: #cbe9b5;
          border-radius: 3px;
          margin: 1px;
          text-align: center;
        }

        &.error {
          background-color: #d6d5d5!important;
          border: 1px solid #155d80!important;
          color: #155d80!important;
        }

        &.ready.but_didnt {
          border: 1px solid #ff7c40!important;
          background-color: #e9b79b!important;
          color: #711414!important;
        }

        &.ready.did_it {
          border: 1px solid #21864b !important;
          background-color: #9aff5e!important;
          color:#21864b!important;
        }

        &.unready {
          background-color: #e6e277;
          border-radius: 3px;
          font-size: 10px;
          margin: 1px;
          text-align: center;
        }

        &.selected {
          border-left-color: $default-color!important;
          border-left-width: 1px!important;
          border-right-color: $default-color!important;
          border-right-width: 1px!important;
        }

        &.weekend {
          background-color: white;
        }

      }

    }
  }

  .summary-table{
    overflow-x: auto;
    overflow-y: auto;
  }

  .summary-table::-webkit-scrollbar-track, .summary-info::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 0;
    background-color: #f1f1f1;
    display: inline-block;
  }

  .summary-table::-webkit-scrollbar, .summary-info::-webkit-scrollbar {
    z-index: 300;
    height: 6px;
    background-color: #cccccc;
    width: 6px;
  }

  .summary-table::-webkit-scrollbar-thumb, .summary-info::-webkit-scrollbar-thumb {
    height: 6px;
    width:6px;
    border-radius: 6px;
    margin-top: -6px;
    background-color: #3E7FA3;
  }

  .summary-info {
    height: 100%;
    width: 300px;
    float: left;
    overflow-y: auto;

    padding: 0 15px;
    border-left: 1px solid #f1f1f1;


    .react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted {
      border-radius: .3rem;
      background-color: #3dcc4a;
      color: #fff;
    }

  }
}

/**
TABLE WIDGET*/

div.scrollable-zone{
  width: 100%;
  display: block;
  overflow: auto;
}
div.scrollable-zone::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}
div.scrollable-zone::-webkit-scrollbar-track-piece { background-color: #d0d0d0;}
div.scrollable-zone::-webkit-scrollbar-thumb { height: 3rem; background-color: $default-color;}
div.scrollable-zone::-webkit-resizer { background-color: #d0d0d0;}

/*SCHEME WIDGET*/

div.scheme{
  background-image: url(../sources/images/testScheme.jpg);
  background-repeat: no-repeat;
  border-radius:20px;
  background-size: 100% 100%;
  margin:20px;
  width:800px;
  height:500px;
  position:relative;
  background-color:transparent;
}



/**
MAP - WIDGET
*/

div.ymaps-logo{
  position: absolute;
  top: -36px;
  left:0;
  background: url('../sources/images/interface/yandex-maps.jpg') 50% 50% no-repeat #ffffff;
  background-size: contain;
}