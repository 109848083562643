@import 'style-constants';

$table-color: #daedfc;
$scenario-control-box: #e8f5fe;

.scenario {
  &-device {
    line-height: 0;
    font-weight: bold;
    color: $default-color;
  }

  &-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-control {
    header {
      font-weight: bold;
      color: $default-color;
    }

    .condition-container {
      display: flex;
      flex-direction: column;
      width: 100%;

      .action-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;

        .action {
          margin-left: 10px;
        }
      }
    }

    .scenario-left {
      align-items: stretch;
      display: flex;
      flex-direction: row;

      label {
        margin-left: 10px;
      }
    }

    .w-200 {
      width: 200px;
    }

    .w-100 {
      padding-left: 5px;
      width: 30%;
    }

    .w-40 {
      padding-left: 5px;
      width: 10%;
    }

    .scenario-right {
      display: flex;
      flex-direction: row;
      &__item {
        &:first-child {
          margin-right: 1rem;
        }
        &:last-child {
          margin-left: 1rem;
        }
      }
    }
  }
}

table.scenario-table {
  border-collapse: collapse;
  height: 1400px;

  thead {
    th {
      color: $default-color-darker;
      padding: 5px;
      background-color: $table-color;

      font-weight: bold;

      &:last-child {
        width: 1%;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 5px;
        color: $default-color-darker;

        .scenario-selected {
          color: red;
        }

        .scenario-edit {
          width: 16px;
        }
      }

      &:nth-child(2n) {
        background-color: $table-color;
      }
    }
  }
}

.status {
  width: 27px;
  height: 27px;
  border-radius: 50%;

  border: 1px solid #fffbfb;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.status-active {
  background: green;
}

.status-disactive {
  background: lightgray;
}

.status-warn {
  background: yellow;
}

.status-error {
  background: red;
}

.scenario-custom {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 1rem;
  margin-right: 1rem;
}
.scenario-custom__input {
  width: 100%;
  //height: 20px;
  margin-bottom: 0.25rem;
  font-weight: bold;
  color: $default-color;
  margin-right: 5px;
  padding: 6px 10px;
  border-radius: 5px;
  background-color: #ececec;
  border: 0;
}
.scenario-custom__datepicker {
  width: 100%;
  height: 20px;
  margin-bottom: 0.25rem;
  font-weight: bold;
  color: $default-color;
  margin-right: 5px;
  padding: 7px 10px;
  border-radius: 5px;
  background-color: #ececec;
}
.scenario-custom__label {
  font-weight: bold;
  color: $default-text-color;
  margin-right: 5px;
  padding: 6px 10px;
  border-radius: 5px;
  background-color: #ececec;
}
