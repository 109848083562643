ul.pagination {

  display: flex;
  justify-content: center;

  li{
    list-style: none;
    padding: 0 0.2rem;
    .btn btn-outline-primary{
      padding: 0;
    }

  }

}