@import 'style-constants.scss';

div.data-loading:hover {
  cursor: wait !important;
  div {
    cursor: wait !important;
  }
}

.dashboard-grid {
  min-height: 20rem;
}

.react-grid-item {
  border: 1px solid lightgrey;
  padding: 6px;
  box-sizing: border-box;
  margin: 0;
  border-radius: 12px;
  background-color: white;
}

.react-grid-item.resizing {
  border-radius: 12px;
}

.react-grid-item.react-grid-placeholder {
  background: $default-color !important;
  opacity: 0.2;
  transition-duration: 100ms;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border-radius: 12px !important;
}

.dashboard-editor-container {
  //background-color: #e7f5ff;
  position: relative;
  width: calc(100% + 2rem);
  margin: 2rem -1rem;
  display: inline-block;

  h5 {
    margin-left: 16px;
    letter-spacing: 0.2rem;
  }
}

.drop-zone {
  //background-color: #e7f5ff;
  min-height: 20rem;
  border-radius: 14px;
  border: 2px dashed #005ea39e;
  background: url('../sources/images/interface/drag-flick.svg') 50% 50% no-repeat #f0f9ff;
  background-size: 120px 120px;
  margin: 14px;
}

ul.capacity-datasource-list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 30rem;
  overflow-y: auto;

  label {
    margin: 1rem 0;
    font-style: italic;
    font-weight: 400;
  }

  li{
    max-height: none!important;
  }

  ul.ds-list-with-info {
    list-style: none;
    padding-bottom: 1rem;
    //border-bottom: 1px solid #d8d8d8;

    li {
      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 0.8rem;

      label.ds-idx {
        margin: 0;
        position: absolute;
        right: 100%;
        padding-right: 1rem;
      }
    }
  }
}


