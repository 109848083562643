@import "style-constants";

.import-test-btn{
    width: auto;
}
.import-test-btn-active{
    background-color: $default-color !important;
    color: white !important;
    border-radius: 1rem;
    border: 1px solid $default-color;
}
